export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro '>
        <div className='overlay'>
            <div className="container">
                <div className="row">
                    <div className="box non-responsive" style={{paddingBottom:100, paddingTop: 100}}>
                        <img src='img/logo.png' alt='' style={{maxWidth: "25%"}} />{' '}
                        <h2 style={{ color: "white", marginLeft: "30px", fontSize: "50px", display:"block"}}>Retard Monkey <br/> Game Studio</h2>
                    </div>
                    <div className="responsive" style={{paddingBottom:50, paddingTop: 50}}>
                        <img src='img/logo.png' alt='' className="img-responsive" style={{maxWidth: "50%", marginRight: "auto", marginLeft: "auto"}}/>{' '}
                        <h2 style={{ color: "white", marginLeft: "30px", fontSize: "36px", textAlign: "center" }}>Retard Monkey <br/> Game Studio</h2>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </header>
  )
}
