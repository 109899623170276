export const Projects = (props) => {
  return (
    <div id='projects'>
      <div className='container'>
        <div className="row" >
          <h1>Projects</h1>
        </div>
        <div className='row' >
            <div className="col-md-3">
              <a href="https://apps.apple.com/us/app/holdem-blitz/id1481440880" target="_blank">
                <img src='img/holdem.png' className='img-responsive' alt='' />
              </a>
            </div>
            <div className="col-md-7 non-responsive" style={{float: "right"}}>
              <img src='img/monkeycoder.gif' className='img-responsive' alt='' />
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">
              <a href="https://apps.apple.com/us/app/lybu/id1516293126" target="_blank">
                <img src="img/lybu.png" className='img-responsive' alt='' />
              </a>
            </div>
            <div className="col-md-3">
                <a href="https://apps.apple.com/us/app/count-legal-vote/id1541982584" target="_blank">
                  <img src="img/countvote.png" className='img-responsive' alt='' />
                </a>
            </div>
            <div className="col-md-3">
                <a href="https://apps.apple.com/us/app/stonk-to-the-moon/id1552597164" target="_blank">
                  <img src="img/stonk.png" className='img-responsive' alt='' />
                </a>
            </div>
            <div className="col-md-3">
                <a href="https://apps.apple.com/us/app/uno-blitz/id1494145644" target="_blank">
                  <img src="img/uno.png" className='img-responsive' alt='' />
                </a>
            </div>
        </div>
      </div>
    </div>
  )
}
